.socials {
  position: fixed;
  right: 0;
  top: 33%;
  display: block;
  white-space: normal;
  margin: 0;
  width: 70px;
  z-index: 999999;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  justify-content: center;
  align-items: center;
  .social-link {
    height: 70px;
    justify-self: center;
    align-self: center;
    justify-content: center;
    align-items: center;
    justify-content: center;
    display: flex;
    a {
      color: white;
    }
  }
  #social-fb {
    background-color: #3b5998;
    color: white;
  }
  #social-github {
    background-color: #24292e;
    color: white;
  }
  #social-instagram {
    color: white;
    background: #f09433;
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  }
}

.button-fb {
  text-decoration: none;
  background-color: #2b4170;
  background: -moz-linear-gradient(top, #3b5998, #2b4170);
  background: -ms-linear-gradient(top, #3b5998, #2b4170);
  background: -webkit-linear-gradient(top, #3b5998, #2b4170);
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, .19) !important;
  color: white;
  margin-bottom: 1rem;
}

.button-ig {
  text-decoration: none;
  color: white;
  background: #f09433;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
  box-shadow: 0 5px 10px 2px rgba(88, 103, 221, .19) !important;
  transition: 1s;
  margin-bottom: 1rem;
}

.button-ig:hover, .button-fb:hover {

}

.tu-0, .tu-0:hover {
  text-decoration: none;
}

.social-fb {
  padding: 20px;
  background-color: #3b5998;
  color: white;
}

.social-ig {
  padding: 20px;
  color: white;
  background: #f09433;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
}