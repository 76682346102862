/* cyrillic */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local('Oswald Regular'), local('Oswald-Regular'), url(https://fonts.gstatic.com/s/oswald/v17/TK3iWkUHHAIjg752HT8Ghe4.woff2) format('woff');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local('Oswald Regular'), local('Oswald-Regular'), url(https://fonts.gstatic.com/s/oswald/v17/TK3iWkUHHAIjg752Fj8Ghe4.woff2) format('woff');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local('Oswald Regular'), local('Oswald-Regular'), url(https://fonts.gstatic.com/s/oswald/v17/TK3iWkUHHAIjg752Fz8Ghe4.woff2) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Oswald';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local('Oswald Regular'), local('Oswald-Regular'), url(https://fonts.gstatic.com/s/oswald/v17/TK3iWkUHHAIjg752GT8G.woff2) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* devanagari */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v6/pxiEyp8kv8JHgFVrJJbecmNE.woff2) format('woff');
  unicode-range: U+0900-097F, U+1CD0-1CF6, U+1CF8-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FB;
}
/* latin-ext */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v6/pxiEyp8kv8JHgFVrJJnecmNE.woff2) format('woff');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: local('Poppins Regular'), local('Poppins-Regular'), url(https://fonts.gstatic.com/s/poppins/v6/pxiEyp8kv8JHgFVrJJfecg.woff2) format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@import "_socials.scss";
@import "_embed.scss";

body {
  font-family: 'Poppins', sans-serif;
}

.nav, .footer {
  a:not(.btn) {
    color: #343a40;
  }
}

a{
  word-wrap: break-word;
}

.bg-krag{
  background-color: hsl(351, 85%, 52%);
  color:white!important;
}

nav, .bg-white{
  background-color:white!important;
}

.nav-item{
  //border-top: 4px solid rgba(0,0,0,0);
  padding-bottom:1rem;
  padding-top:1rem;
}
@media (max-width: 768px) {
  .nav-item {
    padding-bottom:0;
    padding-top:0;
  }

}

.nav-item:hover{
  //border-top: 4px solid hsl(351, 85%, 42%);
  .nav-link{

  }
}
.nav-item::before {
  content: '';
  display: block;
  width: 0;
  height: 3px;
  background-color: rgb(226, 66, 34);
  transition: width .3s;
}

.nav-item:hover::before {
  width: 100%;
  //transition: width .3s;
}
.btn-donate{
  background-color: hsl(149, 100%, 33%);
  color: white;
}
.btn-donate:hover{
  background-color: hsl(149, 100%, 23%);
  color: white;
}
.color-container{
  display:flex;
  .colors{
    width: 5%;
    height: 4px;
    background-color: hsl(45, 100%, 53%);
  }
  .colors:nth-child(6n + 1){
    background-color: hsl(236, 46%, 29%);
  }
  .colors:nth-child(5n){
    background-color: hsl(192, 90%, 51%);
  }
  .colors:nth-child(4n){
    background-color: hsl(149, 100%, 33%);
  }
  .colors:nth-child(3n){
    background-color: hsl(72, 87%, 57%);
  }
  .colors:nth-child(2n){
    background-color: hsl(351, 85%, 52%);
  }
}

.btn-krag-small{
  background-color: rgb(226, 66, 34);
  color: white !important;
}
.btn-krag {
  margin-top:10px;
  background-color: rgb(226, 66, 34);
  color: white;
  font-size: 1.15rem;
  padding: 10px 40px;
}

.btn-krag:hover{
  color: white;
}

.text-important{
  font-family: 'Oswald', sans-serif;
}

.carousel {
  height: 500px;
  .carousel-inner {
    height: 500px;
    background-size: cover;
    background: no-repeat center;
  }
  .carousel-item {
    color: white;
    height: 500px;
    background-size: cover;
    background: url(../img/zespol1.jpg) no-repeat center;
    text-align: center;
    .container {
      height:100%;
      display:flex;
      align-items:center;
      justify-content:center;
      button {
        a {
          text-decoration: none;
        }
      }
      button:hover {
        top: 1px;
      }
    }
  }
  .carousel-item:nth-child(1) {
    padding: 0 20%;
  }
  .carousel-item:nth-child(2) {
    background-size: cover;
    background: url(../img/opera.jpg) no-repeat center;
  }
  /*
  .carousel-item:nth-child(3) {
    background-size: cover;
    background: url(../img/zespol1.jpg) no-repeat center; //festyn aka malkocin
  }*/

  .carousel-item:nth-child(3) {
    background-size: cover;
    background: url(../img/50lat2.jpg) no-repeat center;
  }

}

.dropdown:hover > .dropdown-menu {
  display: block;
}

.dropdown-menu {
  margin: 0;
}
.dropdown-item:active{
  background-color: rgb(226, 66, 34);
  color: white !important;
}

.text-gray {
  color: #343a40 !important;
}

@media (max-width: 768px) {
  .dropdown-menu{
    border:none;
  }
}
@media (max-width: 992px) {
  .container {
    max-width: 100%;
  }
}

.alert-counter {
  padding: 0.8rem;
  border-radius: 3px;
  margin: 20px 0;
  color: #75757c;
  background-color: #f9f9fc;
  border-color: #f7f7fb;
  p {
    margin: 0;
  }
}

.card-main{
  cursor:pointer;
  margin:auto;
  a{
    color: #343a40;
    text-decoration:none;
    img{
      height: 200px;
    }
  }
  .card-body{
    .card-title{
      font-weight: 600;
    }
    a{
      .card-text{
        font-size: 0.8rem;
        margin-bottom: 1.5rem;
      }
    }

  }
}

